import { mainAxios } from "../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "./mainService";

export const reenviarSmsFolio = async (redencionId) => {
  try {
    const response = await mainAxios.post(
      `/v1/redencion/${redencionId}/reenviarFolio`,
      {},
      getDefaultHeaders()
    );

    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
